.mp-Modal {
  position: fixed;
  width: 500px;
  height: 100vh;
  right: -1000px;
  top: 0px;
  bottom: 0px;
  background-color: white;
  box-shadow: 0px 20px 30px grey;
  z-index: 10005;
  transition: 0.4s;
  overflow-y: auto;
  overflow-x: hidden;
}

.mp-Modal-active {
  right: 0px;
}

.mp-Modal-backdrop {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
}

.full {
  right: 0px;
  width: 100%;
}

.mbox {
  padding: 10px;
}

.cusIcon {
  font-size: 30px;
  margin: 10px;
  float: right;
  cursor: pointer;
}

.big-text {
  font-size: 100px;
}

.center-text {
  text-align: center;
}

.cartBox {
  width: 10px;
  height: 10px;
}

.cus-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}

.cus-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}
.labelfont {
  font-size: 14px;
}
